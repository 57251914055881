import React, { useState } from 'react'
import useUserProfile from 'hooks/useUserProfile'
import Script from 'next/script'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSubscribeModalActive } from 'store/subscription.slice'

import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import Modal from 'components/Modal'
import getPlanPrice from 'utils/getPlanPrice'

const publicId = process.env.NEXT_PUBLIC_CLOUDPAYMENTS_PUBLIC_ID

const SubscriptionModal = () => {
  const { data: userProfile, uid } = useUserProfile()
  const { t, i18n } = useTranslation()

  const period = useSelector((state) => state.subscription.period)

  const dispatch = useDispatch()

  const isActive = useSelector(
    (state) => state.subscription.isSubscribeModalActive,
  )

  const plan = useSelector((state) => state.subscription.plan)
  const discount = useSelector((state) => state.subscription.discount)
  const promocode = useSelector((state) => state.subscription.promocode)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const price = getPlanPrice(plan?.price?.[period], discount)

  const handleClose = () => {
    dispatch(setSubscribeModalActive(false))
  }

  const handlePayment = () => {
    if (!isAccepted) {
      setIsSubmitted(true)
      return
    }

    const label = `Подписка на сервис «Юнислайд» по тарифу «${t(
      `plans.${plan.title}`,
    )}» сроком ${period === 'year' ? '1 год' : '1 месяц'}`

    const widget = new global.cp.CloudPayments()
    const receipt = {
      Items: [
        // товарные позиции
        {
          label, // наименование товара
          price: price / 100, // цена
          quantity: 1.0, // количество
          amount: price / 100, // сумма
          method: 0, // тег-1214 признак способа расчета - признак способа расчета
          object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
        },
      ],
      email: userProfile.email, // e-mail покупателя, если нужно отправить письмо с чеком
      phone: '', // телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
      isBso: false, // чек является бланком строгой отчетности
    }

    const data = {
      // дополнительные параметры
      planId: plan.id,
      promocode,
    }
    data.CloudPayments = {
      CustomerReceipt: receipt, // чек для первого платежа
      recurrent: {
        interval: 'Month',
        period: period === 'year' ? 12 : 1,
        customerReceipt: receipt, // чек для регулярных платежей
      },
    } // создание ежемесячной подписки

    widget.charge(
      {
        // options
        publicId,
        description: label, // назначение
        amount: price / 100, // сумма
        currency: 'RUB', // валюта
        accountId: uid, // идентификатор плательщика (обязательно для создания подписки)
        email: userProfile.email, // почта плательщика (обязательно для создания подписки)
        data,
      },
      () => {
        handleClose()
        setIsSuccess(true)
        global.dataLayer.push({
          event: 'logEvent',
          eventType: 'Subscription Activated',
        })
      },
      () => {
        global.dataLayer.push({
          event: 'logEvent',
          eventType: 'Subscription Activation Failed',
        })
      },
    )
  }

  return (
    <>
      <Script
        src="https://widget.cloudpayments.ru/bundles/cloudpayments"
        key="cloudpaymens"
      />
      {plan && (
        <Modal
          show={isActive}
          title={`Подписка на тариф «${t(`plans.${plan.title}`)}»`}
          onClose={handleClose}
        >
          <div>
            <p className="mb-2">
              Вы оформляете подписку на тариф «{t(`plans.${plan.title}`)}».
            </p>
            <p className="mb-2">
              Стоимость составит {(price / 100).toLocaleString(i18n.language)}{' '}
              рублей в {period === 'year' ? 'год' : 'месяц'}.
            </p>
            <p className="mb-4">Подписку можно отменить в любой момент.</p>
            <label
              className={`flex items-start ${
                isSubmitted && !isAccepted
                  ? '-m-1 border border-yellow-500 p-1'
                  : ''
              }`}
            >
              <input
                className="form-checkbox mr-3 mt-2 scale-150 outline-none focus:ring-0"
                type="checkbox"
                checked={isAccepted}
                onChange={() => setIsAccepted(!isAccepted)}
              />
              <span>
                Я даю согласие на регулярные списания и принимаю условия{' '}
                <a
                  className="underline"
                  href="https://cdn.unislide.io/oferta.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  публичной оферты
                </a>
                .
              </span>
            </label>
            <div className="mt-4">
              <Button
                type="button"
                onClick={handlePayment}
                variant="success"
                size="lg"
                block
              >
                Перейти к оплате
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        show={isSuccess}
        onClose={() => setIsSuccess(false)}
        title="Подписка успешно оформлена 🎉"
      >
        <div>
          <p className="mb-2">
            Проводите запоминающиеся презентации с нашим сервисом! По любым
            вопросам пишите в чат поддержки. ❤️
          </p>

          <div className="mt-4">
            <ButtonLink
              href="/my/presentations"
              type="button"
              variant="success"
              size="lg"
              block
              onClick={() => setIsSuccess(false)}
            >
              Перейти к презентациям
            </ButtonLink>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SubscriptionModal
